'use strict'
var __awaiter =
  (this && this.__awaiter) ||
  function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P
        ? value
        : new P(function (resolve) {
            resolve(value)
          })
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value))
        } catch (e) {
          reject(e)
        }
      }
      function rejected(value) {
        try {
          step(generator['throw'](value))
        } catch (e) {
          reject(e)
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected)
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next())
    })
  }
Object.defineProperty(exports, '__esModule', { value: true })
exports.web3Sign = exports.isAwaitingSignature = void 0
const SIGN_AWAIT_KEY = 'signAwait'
const isAwaitingSignature = () => Boolean(window.localStorage.getItem(SIGN_AWAIT_KEY))
exports.isAwaitingSignature = isAwaitingSignature
const web3Sign = (nonce, wallet, library) =>
  __awaiter(void 0, void 0, void 0, function* () {
    try {
      if (library && wallet) {
        window.localStorage.setItem(SIGN_AWAIT_KEY, 'true')
        const signature = yield library.eth.personal.sign(nonce, wallet, '')
        if (!signature) {
          throw new Error('signature error')
        }
        return signature
      }
      throw new Error('signature error')
    } catch (error) {
      console.log('signature error', error)
      throw error
    } finally {
      window.localStorage.removeItem(SIGN_AWAIT_KEY)
    }
  })
exports.web3Sign = web3Sign
