'use strict'
var __awaiter =
  (this && this.__awaiter) ||
  function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P
        ? value
        : new P(function (resolve) {
            resolve(value)
          })
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value))
        } catch (e) {
          reject(e)
        }
      }
      function rejected(value) {
        try {
          step(generator['throw'](value))
        } catch (e) {
          reject(e)
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected)
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next())
    })
  }
Object.defineProperty(exports, '__esModule', { value: true })
exports.revokeToken = exports.refreshRequest = exports.loginRequest = void 0
const dex_guru_fetch_1 = require('@telekomconsalting/dex-guru-fetch')
const loginRequest = (instance, wallet, accountId, nonce, signature, headers) =>
  __awaiter(void 0, void 0, void 0, function* () {
    try {
      return yield (0, dex_guru_fetch_1.apiFetch)(instance, `/auth/login`, {
        init: {
          method: 'POST',
          body: JSON.stringify({
            wallet,
            nonce: nonce || '',
            signature: signature || '',
            account_id: accountId || '',
          }),
          headers,
        },
        onError: (error) => {
          throw error
        },
      })
    } catch (error) {
      console.log('login request error', error)
      throw error
    }
  })
exports.loginRequest = loginRequest
const refreshRequest = (instance, refreshToken, headers) =>
  __awaiter(void 0, void 0, void 0, function* () {
    try {
      return yield (0, dex_guru_fetch_1.apiFetch)(instance, `/auth/refresh`, {
        init: {
          method: 'POST',
          headers: Object.assign(Object.assign({}, headers || {}), {
            Authorization: `Bearer ${refreshToken}`,
          }),
        },
        onError: (error) => {
          throw error
        },
      })
    } catch (error) {
      console.log('refresh request error', error)
      throw error
    }
  })
exports.refreshRequest = refreshRequest
const revokeToken = (url, headers) =>
  __awaiter(void 0, void 0, void 0, function* () {
    return (0, dex_guru_fetch_1.apiFetch)(url, `/auth/access-revoke`, {
      init: { method: 'DELETE', headers },
      onError: (error) => {
        throw error
      },
    })
  })
exports.revokeToken = revokeToken
